import React, { useEffect } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WoocommercedevelopmentFAQ from "../../../FAQPages/TechnologiesFAQ/Woocommercedevelopment";

const Woocommerce = (props) => {
  const cards = [
    {
      name: "Olive Kalyan Mandap",
      type1: "Woocommerce Development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/okm-web.png',
      link: "",
      description:
        "Olive kalyan mandap is event site to book event here.",
      tags: "Web development",
    },
    {
      name: "Olyke Foods",
      type1: "WEBSITE DEVELOPMENT",
      description:
        "Olyke food to selling food through website",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/woocommerecepro.PNG',
      link: "",
      tags: "Web development",
    },
    {
      name: "Ketomeals",
      type1: "WOO Commerce DEVELOPEMNT",
      description:
        "Keyomeal to selling meal items through website.",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/Ketomeals.png',
      link: "",
      tags: "Web development",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/woocommerce-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Woocommerce</small>
              <h2 className="mb-20">
                <span>Woocommerce Development </span>
              </h2>
              <p>We have an experienced team of Woocommerce Development.</p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">WooCommerce Software</small>
                <h2 className="mb-20">
                  <span>Woocommerce CMS Development </span>
                </h2>
                <p>
                  Aara Technologies is a leading woo-commerce development
                  company and this is providing services for WooCommerce
                  Development.This also transform your wordpress website into a
                  cutomize e-Commerce store or MarketPlace by the top Notch
                  WooCommerce Development Company world-wide.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/woo.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4 mb-30">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/demo1.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Magento Development</small>
                      <h2 className="mb-30">
                        Why Should You Choose <span> Woocommerce </span> For
                        Your <span>Online Store Development? </span>
                      </h2>
                    </div>
                    <p>
                      It is easy to pick up if you have any experience with
                      WordPress.If you have already an e_Commerce WEbsite then
                      you can change your plugin into Woo Commerce online store.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png' alt="" className="lines1" />
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        Features Of <span> Woocommerce CMS </span>
                      </h2>
                    </div>
                    <ul>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6>Unlimited categories.</h6>
                      </li>
                      <li className="d-flex">
                        <ArrowRightIcon />
                        <p>Unlimited products and manufacturers.</p>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6>Sell digital products.</h6>
                      </li>
                      <li className="d-flex ">
                        <ArrowRightIcon />
                        <p>Multi currency supported.</p>
                      </li>
                      <li className="mb-20 d-flex ">
                        <ArrowRightIcon />
                        <h6>Multilingual.</h6>
                      </li>
                      <li className="d-flex ">
                        <ArrowRightIcon />
                        <p>Product reviews and product ratings.</p>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6>Can be extended with templates (designs).</h6>
                      </li>
                      <li className="d-flex ">
                        <ArrowRightIcon />
                        <p>More than 20 payment gateways.</p>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6>More than 8 shipping methods.</h6>
                      </li>
                      <li className="d-flex ">
                        <ArrowRightIcon />
                        <p>PCI compliant.</p>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6>Multiple tax rates.</h6>
                      </li>
                      <li className="d-flex ">
                        <ArrowRightIcon />
                        <p>Shipping calculations (weight and size).</p>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6>Discount coupon system.</h6>
                      </li>
                      <li className="d-flex ">
                        <ArrowRightIcon />
                        <p>Backup and restore tools.</p>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6>Printable invoices.</h6>
                      </li>
                      <li className="d-flex ">
                        <ArrowRightIcon />
                        <p>Sales reports.</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/demo1.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/woo-mobile.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">App Development</small>
                      <h2 className="mb-30">
                        <span>Woocommerce App Development</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Aara Technologies India has served mobile applications for
                      many famous brands. Some of them are Nokia, HTC,
                      Accenture, Tech Mahindra, etc. Aara Technology will help
                      you to develop a mobile application for your website that
                      facilitates your users the simple navigation. Also, it
                      helps the users to browse your store anytime from
                      anywhere. Aara Technologies offer both Android and iOS
                      applications for your website. Users will experience a
                      great response time for native or cross-platform
                      applications. It Includes much advanced application
                      development in terms of innovations, augmented reality
                      features, and many more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png" alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">MLM software</small>
                      <h2 className="mb-30">
                        Custom <span> Woocommerce Development </span>
                      </h2>
                    </div>
                    <p className="text ">
                      This is a fact that with the time and constant changes in
                      technology trends or market conditions, you need to be
                      kept updated and also upgrading. Because it is not enough
                      for an online store to be static this want updation
                      timely. In the growing time of digitalization, the users
                      have very high expectations and demands. So the business
                      owners need to take care of the same.
                    </p>
                    <p>
                      Aara Technologies offers customization services as per
                      your business and users’ requirement. It helps you in
                      meeting the mindsets and expectations of your
                      customers.Also, it plays a major role in delivering the
                      best shopping experience to the users by fulfilling their
                      expectations bcz every one are trying to come online or
                      digitsl platform.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/demo1.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/HeroArt-4.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Web App Development</small>
                      <h2 className="mb-30">
                        <span>Woocommerce Marketplace </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      The desired WooCommerce theme can be implemented in your
                      website and application. For the sake of WooCommerce store
                      development, one must have a tremendous theme to attract
                      genuine traffic. We provide unlimited themes to our
                      customers, they are independent to select the field they
                      desire. WooCommerce development has a seamlessly
                      responsive theme, whether you view it on a desktop or
                      mobile device. WooCommerce will help you to develop or
                      change your e-Commerce website into a marketplace
                      according to the customer's need.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">UI Development</small>
                      <h2 className="mb-30">
                        <span> Woocommerce Theme UI </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      UI is the base of any e-Commerce Website, It helps your
                      website look attractive. It"ll help you to design and
                      develop an attractive website. From purchase to
                      fulfillment – manage the entire order process via the app.
                      Scroll the summaries, or search by customer or order
                      specifics. Tap into the details to see itemized billing,
                      begin fulfillment, and change order status.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/theme-ui.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">PWA Development</small>
                      <h2 className="mb-30">
                        <span>Woocommerce PWA </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      We all are aware of the fact that we live in a
                      gadget-driven era. Nowadays, no matter what is the size of
                      your business every business either small, medium or large
                      scale seeks to provide a better experience to their
                      customer. The store owner can integrate headless PWA
                      technology in their store for providing a better app
                      experience for their buyers. PWA technology instigates web
                      compatibilities to give a native-app like experience to
                      their customers. Especially for enterprise-based
                      applications, headless technology is the most important.
                      PWA helps to engage the attention of a new customer who
                      generally does not prefer to download the application of
                      the store first.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">UI Development</small>
                      <h2 className="mb-30">
                        <span> Woocommerce API </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      APIs are present on every tongue of the software or
                      eCommerce industry API is like the heart of websites.
                      Application Programming Interfaces or APIs are the central
                      reason behind all mobile application development. They are
                      present in almost every section of the software world.
                      From making a bus or train reservation to playing a normal
                      game, it is always some APIs that are working behind to
                      manage that specific functionality or task. WooCommerce is
                      fully integrated with the REST API of WordPress. This
                      allows WooCommerce data to be created, read, updated, and
                      deleted.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/angular-spa.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/faq-img.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">B2B Development</small>
                      <h2 className="mb-30">
                        <span>Woocommerce B2B </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      The reason behind B2B preference is the fact that It is
                      helping you to create more business in the market with the
                      help of it, entrepreneurs can create various solutions.
                      Also, it opens up transparency in the transactions within
                      the business. Moreover, the sellers and the buyers always
                      come, when we are talking of B2B commerce bcz all sellers
                      want to sell products b2b, and buyers want to buy products
                      b2b. From all around the globe, sellers and buyers connect
                      on a single platform to carry out various business
                      transactions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Woocommerce</small>
              <h2 className="mb-20">
                Our  <span>Projects </span>
              </h2>
              <p>Here are given our some projects to know about our work.</p>
            </div>

            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <WoocommercedevelopmentFAQ />
    </div>
  );
};

export default Woocommerce;
