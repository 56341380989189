import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AiarproductFAQ from "../../../FAQPages/TechnologiesFAQ/Aiarproduct";
import axios from "axios";
import { baseUrl } from "../../../Config/baseUrl";

const Aiarproduct = (props) => {
  const [meta, setMeta] = useState()
  const cards = [
    {
      name: "AI AR Product",
      type1: "AI DEVELOPMENT",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/a2.webp',
      link: "",
      tags: "VR/AR",
    },
    {
      name: "AI AR Product 2",
      type1: "AI DEVELOPMENT",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/a1.webp',
      link: "",
      tags: "VR/AR",
    },
    {
      name: "AI AR Product 3",
      type1: "AI DEVELOPMENT",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/a3.webp',
      link: "",
      tags: "VR/AR",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${16}`, {
        headers: {
          Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
        console.log(res?.data?.data);
      })

  };
  useEffect(() => {
    getMeta();

  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (<Helmet>
        {/*   <title>{meta.meta_title}</title> */}
        <meta name="title" content={metaItem?.meta_title || 'Default Title'} />
        <meta name="description" content={metaItem?.meta_description || 'Default description'} />
        <meta name="meta_tag" content={metaItem?.meta_tag || 'Default Tag'} />
        <meta name="meta_keyword" content={metaItem?.meta_keyword || 'Default Keyword'} />
        <meta property="og:title" content={metaItem?.og_title || 'Default Title'} />
        <meta property="og:description" content={metaItem?.og_description || 'Default description'} />
        <link rel='canonical' href={metaItem?.canonical} />
        <meta name="og_type" content={metaItem?.og_type || 'Default type'} />
        <meta name="og_url" content={metaItem?.og_url || 'Default description'} />
        <meta name="or_sitename" content={metaItem?.or_sitename || 'Default Tag'} />
        <meta name="twitter_card" content={metaItem?.twitter_card || 'Default card'} />
        <meta property="twitter_description" content={metaItem?.twitter_description || 'Default Title'} />
        <meta property="twitter_image" content={metaItem?.twitter_image || 'Default description'} />
        <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>

      </Helmet>))}
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                <span>AI AR Product</span> Company
              </h2>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">AI AR Product</small>
                <h2 className="mb-20">
                  <span>AI AR Product </span> Development
                </h2>
                <h3 className="mb-20 text-start">Artificial Intelligence</h3>
                <p className="text-start">
                  AI is different from VR and AR, because it doesn't work on the
                  level of user's perception - it is a technology under the hood
                  of the product you use. This is how Spottily knows what to
                  play next after your favourite song. It is the gathering and
                  processing the vast amounts of information to make the user
                  experience better and tailored to the user. Augmented Reality
                  - this is any technology that augments your real life
                  experience with the digital one - you can show your kids a
                  wombat on the floor of their room through the screen on your
                  smartphone.
                </p>
                <h4 className="mb-20 text-start">Augmented Reality</h4>
                <p className="text-start">
                  This is any technology that augments your real life experience
                  with the digital one - you can show your kids a wombat on the
                  floor of their room through the screen on your smartphone.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/ai.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/a1.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>AR & Design</span>
                      </h2>
                    </div>
                    <p className="text">
                      Firstly, you shouldn't create an AR app because it's
                      trendy. AR will never improve an app, in any way, if AR
                      simply doesn't work for the users you're designing for.
                    </p>
                    <h4 className="mb-20">
                      Pokémon Go works really well as an AR app because:
                    </h4>
                    <ol>
                      <li>
                        People are already "out and about", so it stirs up
                        feelings of adventure in a world that might otherwise
                        feel quite mundane otherwise
                      </li>
                      <li>
                        Pokémon as a brand has always been about exploring, or
                        "travel[ing] across the land", if you remember the
                        original lyrics
                      </li>
                    </ol>
                    <p>
                      Augmented reality works best when the user doesn't have to
                      learn a new trick, so take advantage of the technology the
                      user already knows how to use (i.e. the device camera,
                      maps, etc, etc). Lastly, I would recommend that you
                      actually download and use AR apps like Google
                      Translate—the more you familiarize yourself with AR as a
                      user, the easier it'll be to empathize and design for
                      them.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>The Future Of AR, AI, And VR</span>
                      </h2>
                    </div>
                    <p className="text">
                      Designers absolutely need to know about these technologies
                      if they want to stay in the game. It's not to say that
                      we've mastered traditional UX but these concepts certainly
                      aren't new and we should start to feel quite familiar with
                      what users need now. Augmented reality, virtual reality,
                      artificial intelligence and wearable are new the big
                      bosses, and designers need to learn how to kick their ass
                      to beat the game 100%. And even if the mechanics of the VR
                      and AR might not translate to regular UX, the experiments
                      might bring you some new ideas. As for the AI - it is here
                      to stay and rule.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/a2.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/a3.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>What Is Virtual Reality</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Virtual reality is about being immersed in a computerized,
                      three-dimensional environment where fake sounds and
                      visuals trick you into believing the virtual world is
                      actually real. It's designed to boost the gaming
                      experience and other situations where the user wants to
                      experience something as if it were real. I mean, imagine
                      experiencing something, without really experiencing it.
                      Imagine interacting with a menu bar, as if it were really
                      there. All rules are all the window.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Uses Of AI In Augmented Reality</span>
                      </h2>
                    </div>
                    <p className="text">
                      <strong>Pose Estimation </strong> Infer the position of Objects like hands and fingers , which are used to control AI.<br />
                      <strong>Image and Scene Labeling </strong> Classify an Image and trigger an AR Label to be displayed.<br />
                      <strong>Semantic Segmentation and Occlusion </strong> Segment and Occlude any object specified.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/a1.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/a2.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Uses Of AI In Virtual Reality</span>
                      </h2>
                    </div>
                    <p className="text">
                      <strong>Procedural Content Generation</strong> Design Characters , Environment ,other graphical object at scale. <br />
                      <strong>Virtual Humans</strong> Train animation to respond in real time. <br />
                      <strong>Embodied Interaction</strong> Design more natural movement interaction system.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                Our  <span>Projects </span>
              </h2>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <AiarproductFAQ />
    </div>
  );
};

export default Aiarproduct;
