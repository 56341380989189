import React, { useEffect } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import EcommercemarketplacedevelopmentFAQ from "../../../FAQPages/ServicesFAQ/Ecommercemarketplacedevelopment";

const Project = (props) => {
  const cards = [
    {
      name: "Pickle Man Website",
      type1: "Website development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/PickleMan.png",
      link: "",
      description:
        "Pickle Man Website price comparison site to compare price here.",
      tags: "Ecommerce web",
    },
    {
      name: "Thrift Shop Website",
      type1: "WEBSITE DEVELOPMENT",
      description:
        "Thrift shop is an ecommerece website to shop here many products.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/thriftshop.png",
      link: "",
      tags: "Ecommerece web",
    },
    {
      name: "Nabeel Perfumes",
      type1: "GAME DEVELOPEMNT",
      description:
        "Nabeel Perfumes is ecommerce web to sell perfumes of different brands.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/nabeel.png",
      link: "",
      tags: "Ecommerece web",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <h1 name="h1" content={props.h1} h1 />
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/e-commerce-web-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">
                Global Presence with E-Commerce web solutions
              </small>
              <h2 className="mb-20">
                <span> E-Commerce Web Development </span>
              </h2>
              <p>
                We have an experienced team of E-Commerce Web Development in
                worldwide.
              </p>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="mb-10 img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/design-developement.png"
                      className="w-75"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Web Development</small>
                      <h2 className="mb-40">E-Commerce Web Development</h2>
                    </div>
                    <h5>Global Presence with E-Commerce web solutions</h5>
                    <br />
                    <p>
                      To unveil your business in the global market, E-Commerce
                      is really helpful to make things easy for you. It provides
                      a virtual base for your business in the current digital
                      world. The world has already moved to E-Commerce and it
                      helps them in ease of doing business. We are experienced
                      in the design of robust E-Commerce solutions, also
                      delivered various project across the globe. We are
                      specialized in e-commerce services development because our
                      team is fully dedicated and laden with innovative ideas. A
                      big area of the corporate world is using an e-commerce
                      system to help in growing their business to ensure that
                      they procure what they are looking for.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png"
              alt=""
              className="lines1"
            />
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Ecommerce Web development
                      </small>
                      <h2 className="mb-20">
                        <span> Why Choose Us</span>
                      </h2>
                      <h6>Get Benifits and Advantages Market Goal</h6>
                      <br />
                    </div>
                    <ul>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Stylish theme with beautiful UI to hook visitors
                          Stylish theme with beautiful UI to hook visitors.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We provide better quality and speedy work.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Successfully delivered 100+ projects.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          End-to-end Software development solutions from
                          conceptualization to delivery End-to-end Software
                          development solutions from conceptualization to
                          delivery.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Fully customized responsive website and mobile apps.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Clean codes and feature rich themes Clean codes and
                          feature rich themes.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          100% clean W3C compliant code.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Load Testing before go live with internal tools to
                          results in to bug free robust system.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          B2B & B2C based E-Commerce solutions development as
                          per customer need with approved customization.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          demo look and feel get shared with customer and
                          approval process get followed before go live.
                        </h6>
                      </li>
                      <li className="d-flex">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Track progress via easy communication- Chat, Skype,
                          and Phone.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/support.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/Reporting%26BI.webp"
                      className="w-75"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Ecommerce web developement
                      </small>
                      <h2 className="mb-30">
                        Lets <span> Get Started? </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      We offer a powerful combination of profound tech
                      expertise, mature, low-risk processes, and proven
                      experience in a variety of business domains to turn your
                      business idea into reality. Please contact us, and an
                      expert will get back to you ASAP with a free,
                      no-obligation quote.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>

          <div className="integration pt-30" data-scroll-index="3">
            <div className="integration pt-60" data-scroll-index="3">
              <div className="text-center section-head style-4">
                <small className="title_small">Steps For Done</small>
                <h2 className="mb-20">
                  Our <span> Working Process</span>{" "}
                </h2>
              </div>
              <div className="container">
                <div className="content">
                  <div className="img">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/provisioning.png"
                      alt=""
                      className="mt-30"
                    />
                    <h3 className="text-center">1. Provisioning</h3>
                  </div>
                  <div className="img">
                    <h3 className="text-center">2. Strategy</h3>
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/strategy.png"
                      alt=""
                      className="mt-30"
                    />
                  </div>
                  <div className="img">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/design%26developement.png"
                      alt=""
                      className="mt-30"
                    />
                    <h3 className="text-center">3. Development</h3>
                  </div>
                  <div className="img">
                    <h3 className="text-center">4. Go Live</h3>
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/go-live.png"
                      alt=""
                      className="mt-30"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">
                Global Presence with E-Commerce web solutions
              </small>
              <h2 className="mb-20">
                <span> Our Projects </span>
              </h2>
              <p>Here are given some related technologies projects.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage
                            src={card.image}
                            alt=""
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>

      <EcommercemarketplacedevelopmentFAQ />
    </div>
  );
};

export default Project;
