import React, { useEffect } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MlmFAQ from "../../../FAQPages/ServicesFAQ/Mlm";

const Mlm = (props) => {
  const cards = [
    {
      name: "Product Inventory mangement Software",
      type1: "Software development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-inventory-management.jpg",
      link: "",
      description:
        "Product Inventory management Software is the part of cloud based erp software. ",
      tags: "Cloud based software",
    },
    {
      name: "Product human resource management App",
      type1: "Software DEVELOPMENT",
      description:
        "Product human resource management App is the part of cloud based hrm software.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-human-resource-management.jpg",
      link: "",
      tags: "Cloud based software",
    },
    {
      name: "Product multi level marketing software App",
      type1: "Software DEVELOPEMNT",
      description:
        "Product multi level marketing App is the part of cloud based mlm software",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-multi-level-marketing.jpg",
      link: "",
      tags: "Cloud based software",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <h1 name="h1" content={props.h1} h1 />
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/mlm" />

      </Helmet>
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Mlm Development Company</small>
              <h2 className="mb-20">
                <span> Mlm Software Development </span> Company{" "}
              </h2>
              <p>
                We have an experienced team of production and inspection
                personnel to ensure quality.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">MLM Software</small>
                <h2 className="mb-20">
                  <span>MLM Software </span> Company
                </h2>
                <p>
                  Aara technologies is a MLM software development company in
                  Noida, Lucknow, and Delhi, India that provides MLM software.
                  MLM Software Development, otherwise called network marketing
                  software, is an advertising procedure whereby organizations
                  sell products and services through their member network rather
                  than retail outlets. MLM software can assist organizations
                  with smoothing out the basic elements of this model. We will
                  find out the key highlights and advantages of this software in
                  detail. Multi-Level Marketing Software helps to deal with
                  organizations and wholesalers all through the deals and
                  marketing measures, it endues features that help lead
                  generation, marketing, customer management, inventory, and
                  distribution. Multi-level Marketing software is planned for
                  MLM or organization marketing, explicit organizations.
                </p>
                <p>
                  MLM (Multi Level Marketing) development solutions are devised
                  to make MLM operations convenient. Robust & feature-rich MLM
                  solutions are essential for companies that depend on their
                  distributors for marketing company products. Unicode leverages
                  Zendfox MLM, a robust web application framework in providing a
                  wide range of MLM software solutions for diverse business
                  models.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/binary.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">MLM Development</small>
                      <h2 className="mb-30">
                        <span> Binary MLM </span>
                      </h2>
                    </div>

                    <p>
                      Binary MLM Plan is one of the most simple MLM Plans. It is
                      also the most actively and widely used MLM Plan In this
                      plan the profit margin totally depends on the downline
                      being active. The best part of this MLM plan is it
                      inspires the downlines and uplines to work harder and by
                      doing so, creates further growth in the organization. In
                      this, any new member is distributed to the downline
                      irrespective of who recruits them.And we are the best MLM
                      software company that can provide the best Binary MLM plan
                      software for your business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png"
              alt=""
              className="lines1"
            />
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-5 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">MLM software</small>
                      <h2 className="mb-30">
                        <span> Matrix MLM </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      This plan of MLM is also known as the Forced Matrix Plan
                      or Ladder Plan. This plan is very much similar to a
                      pyramid structure. In this plan, the MLM company can set
                      various levels like 2X2 or 3X5, etc. The organizational
                      tree structure is well-defined in its width and depth with
                      a specified ‘n * n’ value. MLM Matrix Plan Calculator or
                      forced matrix calculator, calculate commissions for your
                      multi-level marketing efforts. But it has one limitation,
                      the number of forerunners in an organization setup.And we
                      are the best MLM software company that can provide the
                      best Matrix MLM plan software for your business.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/unilevel.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/mono.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Mlm Software</small>
                      <h2 className="mb-30">
                        <span>Unilevel MLM</span>{" "}
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Unilevel MLM plan is employed by most modern MLM business
                      companies due to their simplicity. This MLM plan works
                      great for both small and large-scale business
                      organizations. Today nearly all of the MLM companies work
                      with Uni-level Plan as their basic plan and customize it
                      for a more attractive image. One of the generally used
                      customizations in the uni-level MLM plan is the control of
                      the payment system by covering the least amount of user
                      volume(business volume), an extra measure to pay out the
                      commission.And we are the best MLM software company that
                      can provide the best Unilevel MLM plan software for your
                      business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-5 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">MLM software</small>
                      <h2 className="mb-30">
                        <span> Key Benefits MLM Software </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      MLM Software is a leading MLM Software that is expertise
                      in offering unique features in every Network Marketing
                      Business plan software. The custom & reliable MLM
                      management software developed by our MLM software company
                      ensures to fit any business model, satisfy customer
                      requirements, and face the problematic challenges of the
                      Direct Selling industry.
                    </p>
                    <ul>
                      <li className="d-flex align-items-center op-4">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Easy to integrate rewards and bonuses
                        </h6>
                      </li>
                      <li className="d-flex align-items-center ">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Simple and easy to explain to newcomers
                        </h6>
                      </li>
                      <li className="d-flex align-items-center op-4">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Customized Compensation level as per requirements
                        </h6>
                      </li>
                      <li className="d-flex align-items-center op-4">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Safe and Secure MLM System</h6>
                      </li>
                      <li className="d-flex align-items-center op-4">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Multiple MLM Business Plans</h6>
                      </li>
                      <li className="d-flex align-items-center op-4">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Easy Customization</h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/mlm-software.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Mlm Development Company</small>
              <h2 className="mb-20">
                Our <span>Projects</span>
              </h2>
              <p>Here are given our latest projects given below.</p>
            </div>

            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <MlmFAQ />
    </div>
  );
};

export default Mlm;
