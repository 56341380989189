/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PrestashopdevelopmentFAQ from "../../../FAQPages/TechnologiesFAQ/Prestashopdevelopment";

const Prestashop = (props) => {
  const cards = [
    {
      name: "JMD Fire Website",
      type1: "Web Development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/jmdfire.png',
      link: "",
      description:
        "JMD Fire website to sell automobile part in different cities",
      tags: "Prestashop",
    },
    {
      name: "Central Public School JamshedPur",
      type1: "WEBSITE DEVELOPMENT",
      description:
        "Central Public School JamshedPur is educational website.",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/cpsjamshedpur.png',
      link: "",
      tags: "Prestashop",
    },
    {
      name: "Ketomeals Website",
      type1: "Web DEVELOPEMNT",
      description:
        "Ketomeals website is resturant site to make a diet plan.",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/Ketomeals.png',
      link: "",
      tags: "Prestashop",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/prestashop-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Web Development</small>
              <h2 className="mb-20">
                <span>PrestaShop Development</span>
              </h2>
              <p>
                We have an experienced team of PrestaShop Development that's
                provide best quality.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">Magento Software</small>
                <h2 className="mb-20">
                  <span>PrestaShop Development </span>
                </h2>
                <p>
                  Aara Technologies is the top-rated PrestaShop Development
                  Company in Noida & Lucknow, We have developed an e-Commerce
                  Store, Marketplace, and extensions to suit every businessman
                  for Online Business.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/prestashop.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/presta-shop-google-trends.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Magento Development</small>
                      <h2 className="mb-30">
                        <span> Google Trends Of Prestashop </span>
                      </h2>
                    </div>
                    <p>
                      Google Trends is a free online tool to search for trends.
                      It is based on the number of times a keyword is searched
                      on Google over a period of time. And since it also
                      integrates geographic data, it is particularly useful for
                      local SEO. you'll find out some important information that
                      you can use to your advantage if you run an online
                      business. You will also learn how to use Google Trends for
                      keyword research as well as some important tips you should
                      keep in mind.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png' alt="" className="lines1" />
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="info">
                  <div className="section-head style-4">
                    <h2 className="mb-30">
                      Key Advantages Of Having A{" "}
                      <span> PrestaShop Development </span>
                    </h2>
                  </div>
                  <p className="mb-40 text">
                    PrestaShop is an online open-source platform that has more
                    than 2 to 3 lakhs store running on it worldwide.
                  </p>
                  <Row className="mb-20">
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/Prestashop-icons/Opensource.svg" alt="" />
                          <Card.Subtitle>OpenSource Platform</Card.Subtitle>
                          <Card.Text>
                            Donation from all large community of developers
                            making it feature rich Day-by-Day.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/Prestashop-icons/Inbuild-SEO.svg" alt="" />
                          <br />
                          <Card.Subtitle>Inbuild SEO</Card.Subtitle>
                          <Card.Text>
                            Integrating all SEO features to improve the
                            visibility of your store
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/Prestashop-icons/CMS-Channels.svg" alt="" />
                          <Card.Subtitle>CMS Channels</Card.Subtitle>
                          <Card.Text>
                            Providing a dedicated CMS Channel for the effective
                            management of your store
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-20">
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/Prestashop-icons/Store-Scalability.svg"
                            className="mb-20"
                          />
                          <Card.Subtitle>Store Scalability</Card.Subtitle>
                          <Card.Text>
                            PrestaShop is supporting multi store and multi
                            language functionality also.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/Prestashop-icons/Micro-Service.svg" alt="" />
                          <br />
                          <Card.Subtitle>Micro Service</Card.Subtitle>
                          <Card.Text>
                            By the help of existing module add-on-marketplace
                            which helps you to increase traffic, Improve
                            conversion rate and will help you to
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/Prestashop-icons/Themes-Available.svg" alt="" />
                          <Card.Subtitle>Themes Available</Card.Subtitle>
                          <Card.Text>
                            This has multiple themes clients can choose
                            according to their business requirements. Fast
                            Rendering: Its's has a single dashboard with a
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/Prestashop-icons/Fast-Rendering.svg"
                            className="mb-20"
                          />
                          <Card.Subtitle>Fast Rendering</Card.Subtitle>
                          <Card.Text>
                            Its’s having a single dashboard with a highly
                            customizable and scalable storefront also.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/Prestashop-icons/Product-Support.svg"
                            className="mb-20"
                          />
                          <br />
                          <Card.Subtitle>Product Supports</Card.Subtitle>
                          <Card.Text>
                            This is having functionality of product options like
                            simple, Variation, group, Downloadable, etc
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/Prestashop-icons/Data-Migratiom.svg"
                            className="mb-20 "
                          />
                          <Card.Subtitle>Data Migration</Card.Subtitle>
                          <Card.Text>By this we can migrate data</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>

          <section className="mb-20 team section-padding style-6">
            <div className="content1">
              <div className="container">
                <div className="text-center section-head style-4 mb-30">
                  <h2>
                    <span>
                      We Delivered 1500+ Enterprise Website For Different
                      Verticals
                    </span>
                  </h2>
                </div>
                <h6 className="mb-20">
                  We Aara Technologies Pvt. Ltd. Have delivered 1500+ enterprise
                  websites all over the World. Which is having multiple advanced
                  functionalities which will make easy your business or reduce
                  the complexity of your business.
                </h6>
                <ul>
                  <li className="mb-20 d-flex align-items-center op-4">
                    <ArrowRightIcon />
                    <h6 className="fw-bold">
                      Migrations are done with utmost care to prevent any kind
                      of data loss.
                    </h6>
                  </li>
                  <li className="mb-20 d-flex align-items-center">
                    <ArrowRightIcon />
                    <h6 className="fw-bold">
                      We analyze the existing website thoroughly to provide you
                      with the best possible solution for migration.
                    </h6>
                  </li>
                  <li className="mb-20 d-flex align-items-center op-4">
                    <ArrowRightIcon />
                    <h6 className="fw-bold">
                      Useful inputs related to migration upon careful analysis
                      delivered by our technical team at each stage.
                    </h6>
                  </li>
                  <li className="d-flex align-items-center op-4 ">
                    <ArrowRightIcon />
                    <h6 className="fw-bold">
                      After migration follow up to provide extra technical
                      support in case of any issues.
                    </h6>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/data-migration.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">E-Commerce</small>
                      <h2 className="mb-30">
                        <span>PrestaShop Migration</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      PrestaShop is creating a Module also, and we are also
                      providing migration services to our clients. Whether you
                      can migrate the old version to the new version of
                      PrestaShop or from another platform. We are always here to
                      your support
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">PrestaShop development</small>
                      <h2 className="mb-30">
                        <span> PrestaShop Marketplace </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Are you looking to start your online business then
                      PrestaShop marketplace will help you run your business in
                      the market. You would turn your shop into your online
                      e-commerce platform which is called Marketplace. Apart
                      from that we are providing customized services to our
                      clients according to their needs. This is not we are
                      providing a marketplace it is a place where we are trying
                      to provide you with all necessary goods in a single place.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/prestashop-marketplace.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/customization.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Ui Development</small>
                      <h2 className="mb-30">
                        <span>PrestaShop Customization </span> Services
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Increasing Users base online store continuously demands
                      the latest functionality. We will understand what they
                      already developed or services that need some more changes
                      in technologies as well functionality also. We are
                      offering Customization Services also:
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center op-4">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Customization of existing modules as per client's
                          requirement.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          A new customized on-demand module/extension with
                          required features.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center op-4">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Deployment of the customized modules after thorough
                          testing by developers and testers both.
                        </h6>
                      </li>
                      <li className="d-flex align-items-center op-4 ">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Technical support after the deployment of the
                          customized module.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">PrestaShop software</small>
                      <h2 className="mb-30">
                        <span> PrestaShop Theme </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      When anyone going to open an online store, most
                      businessmen are worried to integrate the features and
                      functionality first, and they should be. In all of these,
                      they are going to forget the most important things:
                      appearance/ aesthetics.
                    </p>
                    <h5>
                      What if the appearance of your website does not resonate
                      with the products you offer?
                    </h5>
                    <br />
                    <p>
                      Then it might leave you with unaccountable losses.
                      Therefore, in order to minimize the effect of this, you
                      must apply a theme that in some way resembles your product
                      and services. So that you get the customer’s attention and
                      curiosity for long enough to crawl through your website
                      pages.
                      <br />
                      <br />
                      But, don’t you worry even for a minute; we’ve got you
                      covered even here. From some already developed themes to
                      customized themes, we have it all. We work together with
                      the clients to develop the theme that is specifically
                      developed according to their requirement.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/prestashop-theme-development.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/payment-gateway.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Ui Development</small>
                      <h2 className="mb-30">
                        <span>PrestaShop Shipping & Payment Integrations </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      We all understand how simple inefficiency is in an
                      eCommerce domain, sometimes it's cost high, and lots of
                      sales to merchants. Many of the time we are facing the
                      problem of unavailability of suitable shipping or payment
                      method options. Most of the customers are leaving the
                      website due to unavailability of multiple payment methods,
                      In order to minimize the cart losses occurring dues we
                      have
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center op-4">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Developed multiple shipping extensions as well as
                          integrated shipping services like UPS, USPS, etc.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Crafted payment extensions for PrestaShop like PayPal,
                          Braintree, Stripe, etc.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center op-4">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Developed some custom payment and shipping solutions
                          in order to increase the productivity of the store.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">POS software</small>
                      <h2 className="mb-30">
                        <span> PrestaShop POS </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      In current market conditions, POS is quite used by
                      merchants all over the world. They are not trying to help
                      merchants to increase efficiency but it is fast and
                      reliable too. While we continuously work towards making
                      the PrestaShop POS module even better, it might not even
                      suit everyone's needs. And, in case of such an event, we
                      also offer customization services on the POS module
                      according to clients’ needs. Our customization costs are
                      as per industry standards and purely based on the efforts
                      required to apply the demanded tweaks.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/odoo-pos.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row justify-content-between">
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <h2>
                        We are ready to work and build the on-demand solution
                        for your business.
                      </h2>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>

          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="section-head style-4">
                  <small className="title_small">
                    <strong>FAQ's</strong>
                  </small>
                </div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h5>
                        Why should you use the PrestaShop Development Service?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      The reasons to consider PrestaShop starting your own
                      eCommerce business are:
                      <br />
                      <strong>Scalability:</strong> You can run multiple stores
                      on it and is very suitable for the needs of new eCommerce
                      ventures.
                      <br />
                      <strong>Products:</strong> Helps you to create multiple
                      types of products like Standard, Virtual, Pack, etc.
                      <br />
                      <strong>Themes:</strong> Range of interactive themes
                      available to suit the products. Content Management:
                      Integrated CMS tool for efficient management of content
                      available at the store.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <h5>
                        {" "}
                        Will I be able to create a marketplace on this platform?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, our POS plugins are open-source. You can modify the
                      POS as per your needs just by playing with the code.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <h5>
                        I want to run a physical as well as an eCommerce store.
                        And, I want to keep track of all the sales that are
                        being made through both channels.
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      The solution is POS. Every sale you make through the
                      physical channel can be easily synchronized with the
                      online platform.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <h5>How do you create technology-driven products?</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      We continuously monitor the market requirements by
                      analyzing the custom requests made by the clients.
                      Data-wise, we see a way to contribute to the eCommerce
                      community.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <h5>
                        Will you be able to help me with my custom requirements
                        and store setup?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, we provide this type of service to the customers.
                      From setting up your shop to having all the custom-made
                      features you need to function to your full potential.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Web Development</small>
              <h2 className="mb-20">
                Our  <span>Projects</span>
              </h2>
              <p>Here are given our some projects to know about our work.</p>
            </div>

            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <PrestashopdevelopmentFAQ />
    </div>
  );
};

export default Prestashop;
