import React, { useEffect } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CustomsoftwaredevelopmentFAQ from "../../../FAQPages/ServicesFAQ/Customsoftwaredevelopment";

const Project = (props) => {
  const cards = [
    {
      name: "NRP Software",
      type1: "Software",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/nrp-web.png',
      link: "",
      description:
        "NRP is total sarvey site for doing servey",
      tags: "Servey site",
    },
    {
      name: "NRP APP",
      type1: "WEBSITE DEVELOPMENT, UI/UX DESIGN",
      description:
        "NRP is total sarvey app for doing servey in different area.",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/nrp-app.png',
      link: "",
      tags: "Mobile app",
    },
    {
      name: "ZZZ Mart",
      type1: "App Mobility",
      description:
        "ZZZ Mart is totally ecommerce app for selling products. ",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/zzzmart-app.png',
      link: "",
      tags: "Mobile App",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/customized-software-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-20 text-center section-head style-4">
              <small className="title_small">Customized Software</small>
              <h2 className="mb-20">
                <span>Customized Cloud Software Development </span>
              </h2>
              <p>
                We have an experienced team of Customized Cloud Software
                Development in worldwide.
              </p>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="mb-10 img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/software-3.webp"
                      className="w-75"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Customized Software</small>
                      <h2 className="mb-40">
                        Customized Cloud Based Open Source Software Development
                      </h2>
                    </div>
                    <p>
                      An Customized Cloud based Open Source Software is a cloud
                      in any cloud services or solutions that is developed using
                      Open Source technologies and software. This cover all like
                      public, private, and hybrid cloud model.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png' alt="" className="lines1" />
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>

          <div className="content sec-content bgmad">
            <div className="container">
              <div className="row align-items-center ">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">OSS Solutions</small>
                      <h2 className="mb-20">
                        <span>Open Source Software OSS Solutions</span>
                      </h2>
                      <p>
                        At Aara Technologies,we take the time to understand your
                        requirements and projects gathering, wireframing,
                        designing your interface(UI/UX)developing, and delyoing
                        your current project according to the requirements to
                        drive your business forward into the future.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/rafiki+(1).png"
                      className="ml-40 w-75"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      className="w-75"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">E-Commerce Solution</small>
                      <h2 className="mb-30">
                        <span> Open Source E-Commerce </span> Solution
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      We Aara Technologies are providing e-Commerce solutions
                      that offer complete flexibility and control over the
                      online platforms, create sites and provide a well round
                      customer experience using SEO and various marketing Tools.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>

          <div className="content sec-content bgmad">
            <div className="container">
              <div className="row align-items-center ">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Benefits</small>
                      <h2 className="mb-20">
                        Benefits Of <span> Open Source</span> <br />
                        <span> Customize Software</span>
                      </h2>
                      <ul>
                        <li className="mb-20 d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6 className="fw-bold">Enhance probability.</h6>
                        </li>
                        <li className="mb-20 d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6 className="fw-bold">Vendor Neutrality.</h6>
                        </li>
                        <li className="mb-20 d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6 className="fw-bold">
                            Reduce development time of projects.
                          </h6>
                        </li>
                        <li className="mb-20 d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6 className="fw-bold">
                            Flexibility in specific meeting.
                          </h6>
                        </li>
                        <li className="mb-20 d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6 className="fw-bold">Long Term Customers support.</h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/amc.webp"
                      className="ml-40 w-75"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>

          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/erp-3.webp"
                      className="w-75"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Software Integration</small>
                      <h2 className="mb-30">
                        <span> Open Source Software Integration </span>
                      </h2>
                    </div>
                    <p>
                      When any project is specifically cost-sensitive and that
                      include high degree flexibility, it is often possible to
                      integrate Open Source Software Solution in complete
                      product.
                    </p>
                    <p>
                      Based on our wealth experience in Open Source Software
                      Integration, Aara Technologies will provide you with most
                      efficient , and cost –effective solution possible for your
                      requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>

          <div className="content sec-content bgmad">
            <div className="container">
              <div className="row align-items-center ">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Migration</small>
                      <h2 className="mb-20">
                        <span>Open Source Migration</span>
                      </h2>
                      <p>
                        Growing Operation and project cost and the lack of
                        performance and scalability limitations in much
                        enterprise’s software applications have led their
                        decision makers to realize that migrating to an open
                        source solution. Aara Technologies Pvt. Ltd. Delivers
                        expertise in successfully migrating to open source
                        software solution.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/erp-5.webp"
                      className="ml-40 w-75"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>

          <div className="integration pt-30" data-scroll-index="3">
            <div className="integration pt-60" data-scroll-index="3">
              <div className="text-center section-head style-4">
                <small className="title_small">Steps For Done</small>
                <h2 className="mb-20">
                  Our <span> Working Process</span>{" "}
                </h2>
              </div>
              <div className="container">
                <div className="content">
                  <div className="img">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/provisioning.png"
                      alt=""
                      className="mt-30"
                    />
                    <h3 className="text-center">1. Provisioning</h3>
                  </div>
                  <div className="img">
                    <h3 className="text-center">2. Strategy</h3>
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/strategy.png"
                      alt=""
                      className="mt-30"
                    />
                  </div>
                  <div className="img">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/design%26developement.png"
                      alt=""
                      className="mt-30"
                    />
                    <h3 className="text-center">3. Development</h3>
                  </div>
                  <div className="img">
                    <h3 className="text-center">4. Go Live</h3>
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/go-live.png"
                      alt=""
                      className="mt-30"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Customized Software</small>
              <h2 className="mb-20">
                Our  <span>Projects </span>
              </h2>
              <p>
                Here are given some our projects.
              </p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <CustomsoftwaredevelopmentFAQ />
    </div>
  );
};

export default Project;
