import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CodeigniterdevelopmentFAQ from "../../../FAQPages/TechnologiesFAQ/Codeigniterdevelopment";

const Reactnative = (props) => {
  const cards = [
    {
      name: "virtual design hub",
      type1: "Web development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/virtualdesignhub.png',
      tags: "CI Development",
    },
    {
      name: "Thrift shop Web",
      type1: "WEBSITE DEVELOPMENT",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/thriftshop.png',
      tags: "CI Development",
    },
    {
      name: "cripl website",
      type1: "Web development",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/cripl.png',
      tags: "CI Development",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/codeigniter-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                <span>CodeIgniter Development</span> Company
              </h2>
              <p>
                CodeIgniter is a very popular Web application framework and Aara
                technologies is the most experience in CI Development.
              </p>
            </div>

          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">App Development</small>
                <h2 className="mb-20">
                  <span>CodeIgniter </span> Development
                </h2>
                <p>
                  Being a top CodeIgniter development company, we offer
                  CodeIgniter development services that include custom
                  development, customization, CRM,eCommerce, marketplace, and
                  API integrations.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/technologies-based-banner.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/Business-solution.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>
                          Looking For The Right Solution For A New Business?
                        </span>
                      </h2>
                    </div>
                    <p className="text">
                      Codeigniter is an open-source and lightweight PHP
                      framework. It is a powerful framework for software
                      developers to build dynamic and instinctive web
                      applications. Developers use it to build elegant websites
                      and web applications rapidly. Codeigniter helps in
                      reducing the effort needed for writing complex code and
                      allows the developer to focus creatively on the web
                      application. At Codeigniter India, we have a talented team
                      of developers with the latest skills in code libraries
                      that help us in delivering outstanding and secure web
                      applications. With our experience in delivering
                      Codeigniter applications across multiple domains, we are
                      ready to accept any complex project. We develop corporate
                      websites, professional websites, and business portals for
                      our clients. You just tell us your requirements and we
                      will build that application for you.
                    </p>
                    <p>
                      Codeigniter is highly suitable for any kind of business
                      application from eCommerce to CRM software, from CMS to
                      SaaS-based apps. Loads of packages are inbuilt into the
                      core of Laravel which makes development lighting fast and
                      easy to scale.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-30">
                  <span>
                    We Have Worked Across Different Verticals And Industries
                  </span>
                </h2>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/icons/User-Friendly.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>User-Friendly</Card.Subtitle>
                        <Card.Text>
                          It is simple and easy to deploy framework. Developers
                          enjoy working with its user-friendly interface and
                          develop secure as well as flexible
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/icons/Built-in-Libraries.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Built-in Libraries</Card.Subtitle>
                        <Card.Text>
                          It comes with full packet libraries that enable all
                          the web-needed tasks like database, form validation,
                          sending an email, manipulating
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/icons/Community-Support.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Community Support</Card.Subtitle>
                        <Card.Text>
                          URLs generated by the Codeigniter framework are search
                          engine friendly. This helps in the better ranking of
                          web applications and drives quality visitors.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/icons/High-Performance.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>High Performance</Card.Subtitle>
                        <Card.Text>
                          It is a scalable and high-performance framework that
                          guarantees feature-rich and reactive web applications.
                          Users like smooth-working web
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/icons/Highly-Secure.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Highly Secure</Card.Subtitle>
                        <Card.Text>
                          The security tools are already built-in making web
                          application development a secure process. It hosts
                          several security standards like cookie
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/icons/SEO-friendly-url.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>
                          Generate SEO-Friendly URLs
                        </Card.Subtitle>
                        <Card.Text>
                          URLs generated by the Codeigniter framework are search
                          engine friendly. This helps in the better ranking of
                          web applications and drives quality visitors.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Here Are The Services Includes For CodeIgniter
                        Development
                      </small>
                      <h2 className="mb-30">
                        <span>Complex Business Application Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      We have been continuously developing small and complex
                      business applications for various brands around the globe.
                      Our technical expert team provides a transparent
                      development process with superior-quality apps. We have
                      over a decade of experience in the eCommerce world and
                      software development along with POS, Multitenant SaaS,
                      PWA, etc. Whether it is a small application or a complex
                      one, our expert team will always provide the best solution
                      to our clients within an appropriate time.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/application-development.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/woo-mobile.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>ECommerce And Marketplace Development</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      CodeIgniter is well-known for its endless features. It is
                      highly scalable and secure which makes development easier,
                      and faster and increases performance. We develop a
                      CodeIgniter-based open-source eCommerce platform. It
                      provides a wide range of functionality, and full control
                      over the store and even you can create a marketplace as
                      well. The platform allows easy customization and is easy
                      to use for non-tech services. With the help of our
                      multi-vendor module, you can also create a marketplace. An
                      online platform where several vendors can assemble
                      together to sell their products in a single eCommerce
                      store.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Headless And Microservices</span>
                      </h2>
                    </div>
                    <p className="text">
                      One of the best feature of CodeIgniter is that it supports
                      Microservices which is very beneficial to create an
                      application. The application is developed in various parts
                      which makes implementation easier. These small parts are
                      connected with each other via API and create a fully
                      functioning application. Headless CMS along with
                      CodeIgniter JSON API helps you to quickly build apps with
                      a nice architecture that can easily run across different
                      platforms and operating systems.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/headless-and-microservices.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png" alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>
                          Mobile Apps
                          <br /> Development
                        </span>
                      </h2>
                    </div>
                    <p className="text">
                      CodeIgniter is also popular for mobile app development as
                      it offers predefined libraries that make app development
                      easier. Laravel is the most recognized PHP framework
                      within Github. And also, the most preferred choice for
                      companies and developers to create an app. This PHP is one
                      of the most popular frameworks amongst developers to
                      develop a mobile app as it offers improvement in
                      functionalities and many built-in features as well as
                      security.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>CodeIgniter Services And API Integration</span>
                      </h2>
                    </div>
                    <p className="text">
                      CodeIgniter is an appropriate choice to create an API. We
                      can use API in several areas, build a PWA to integrate
                      online shopping applications or integrate with Customer
                      Resource Management, etc.API can be used in several areas
                      like customer authentication, performing CRUD operations,
                      etc. Also, with the help of web API, you can integrate
                      your application to serve the default content.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/ci-api.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">Web Development</small>
                <h2 className="mb-20">
                  <span>
                    Support & Development Team Is Known For Its Good Service &
                    Experience
                  </span>
                </h2>
                <p>
                  From purchase to fulfillment – manage the entire order process
                  via the app. Scroll the summaries, or search by customer or
                  order specifics. Tap into the details to see itemized billing,
                  begin fulfillment, and change order status.
                </p>
                <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/support%26development.webp" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                Our  <span>Projects </span>
              </h2>
              <p>Here are given our some projects to know about our work.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <CodeigniterdevelopmentFAQ />
    </div>
  );
};

export default Reactnative;
