import React, { useEffect } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Faq from "../../FAQ";
import ReactnativedevelopmentFAQ from "../../../FAQPages/TechnologiesFAQ/Reactnativedevelopment";


const Reactnative = (props) => {
  const cards = [
    {
      name: "Taiton App",
      type1: "React-native",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/taiton-app.png',
      link: "",
      description:
        "Taiton app is make on to sell harware part through app",
      tags: "Mobile app development",
    },
    {
      name: "Amaka Industry App",
      type1: "React-native development",
      description:
        "Amaka Industry App is sell grocery item through mobile app",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/grocery1.png',
      link: "",
      tags: "Mobile app development",
    },
    {
      name: "Education App",
      type1: "React-native DEVELOPEMNT",
      description:
        "Education app is fully learning app to use many schools.",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/buss.png',
      link: "",
      tags: "Mobile app development",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/react-native-development" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                <span>React Native App Development</span> Company
              </h2>
              <p>
                React-Native is a very popular Mobile application framework,
                Aara technologies is the top React Native Development Company
                which is knowledge to having hands on experience.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">App Development</small>
                <h2 className="mb-20">
                  <span>React Native App </span> Development
                </h2>
                <p>
                  React-Native is a very popular Mobile application framework,
                  That allows you to build natively –rendered mobile apps for
                  iOS and Android. The framework lets you can create an
                  application with the same code on various platforms. React
                  Native will make sure that the Text results with a native iOS
                  UIView containing the text. If we are building an Android
                  application, it will result in a native TextView. This is very
                  important.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/react-native.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  Thinking Of <span>Building A New React App?</span>
                </h2>
                <p>
                  You are thinking to build a Mobile application for your
                  business and you are looking to move on a Digital Platform?
                  React-Native is used for developing mobile applications with
                  the latest technology with advanced features.
                </p>
                <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-project.webp" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="section-head style-4">
                  <h2 className="mb-30">
                    Here Are The Main{" "}
                    <span> Features Of The React Native App Development</span>
                  </h2>
                </div>
                <div className="order-2 text-center col-lg-6 order-lg-0">
                  <div className="info">
                    <p className="text ">
                      Key features of React Native App Development.
                    </p>
                    <ul >
                      <li>
                        <p>Write Once use anywhere.</p>
                      </li>
                      <li>
                        <p>UI Focused.</p>
                      </li>
                      <li>
                        <p>Programming Languages.</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="order-2 text-center col-lg-6 order-lg-0">
                  <div className="info">
                    <ul >
                      <li>
                        <p>Strong Community.</p>
                      </li>
                      <li>
                        <p>Support for 3rd party Libraries.</p>
                      </li>
                      <li>
                        <p>NPM for Installation.</p>
                      </li>
                      <li>
                        <p>Mobile Environment perform.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png' alt="" className="bubble2" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-development.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Native Development For Everyone</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      React Native is used for developing Native Mobile
                      applications. The term native app development refers to
                      building a mobile app exclusively for a single platform.
                      For example, you can develop a native Android app with
                      Java or Kotlin and choose Swift and Objective-C for iOS
                      apps. Native apps are known to deliver exceptional user
                      experience as they are generally high-performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Seamless Cross-Platform</span>
                      </h2>
                    </div>
                    <p className="text">
                      Cross-platform application development is about building a
                      single application that can run on various operating
                      systems, instead of developing different app versions for
                      each platform. The term “cross-platform mobile app
                      development” or “hybrid mobile app development” speaks for
                      itself: this is an approach that allows developers to
                      build a mobile solution that is simultaneously compatible
                      with several operating systems and platforms (Android,
                      iOS, Windows).
                    </p>
                    <p>
                      Hybrid applications have a native look and feel due to the
                      combination of native code with independent code that is
                      suitable for multiple platforms. Developers write the code
                      once and then reuse it, thus making it possible to release
                      a product quickly. To code cross-platform software,
                      developers use intermediate programming languages — HTML,
                      JavaScript, and CSS — not native to devices and OSs. Then,
                      apps are packaged into native containers and integrated
                      into platform
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/headless-and-microservices.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/react-fast-refresh.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Fast Refresh</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      React native app development refresh is very fast. If you
                      are using any React native Mobile application and you want
                      to check the updates at the current time you can refresh
                      at the same time and the process of refreshing is very
                      fast for all react native mobile applications.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="section-head style-4">
                  <h2 className="mb-30">
                    <span>Start Your Business With Our Creative Apps</span>
                  </h2>
                </div>
                <p>
                  We are Aara technologies, we are one of the best leading
                  mobile application development companies. You are also looking
                  to move your business online? Start your online business with
                  creative mobile applications.
                </p>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>React Native App UI/UX Design</span>
                      </h2>
                    </div>
                    <p className="text">
                      With this cross-platform design approach, the design logic
                      remains shared across both platforms — as if you opted for
                      ‘Universal design’ approach. At the same time core
                      functional components (like search boxes or buttons) are
                      designed for each OS separately — as if you followed the
                      ‘Multiplatform design’ approach.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-ui.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Enterprise App Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      Enterprise application development is evolving to become
                      more agile and collaborative where IT works to build
                      applications both with internal business teams and
                      agencies external to the company — better understanding
                      and incorporating the end user’s needs. This is primarily
                      enabled by the rapid adoption of cloud technologies.
                    </p>
                    <p>
                      Historically, businesses would build the software they
                      needed by taking advantage of large teams of in-house
                      resources, using a lengthy, rigid waterfall process. This
                      was primarily due to the limitations of the technology.
                      On-premise approaches using Java, .Net, and other options
                      kept the individual steps within application development
                      lifecycle rigid and siloed, limiting collaboration and
                      rapid iteration.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>App Consultation</span>
                      </h2>
                    </div>
                    <p className="text">
                      The consultation apps or software are armed with default
                      features like appointment scheduling, calendar, and
                      reminders on appointments.
                    </p>
                    <h4 className="mb-20">
                      Some Virtual Features for Consultation Apps to Integrate
                    </h4>
                    <ol className="list-style-two">
                      <li>Peer-to-peer Video Calling.</li>
                      <li>Group Calls.</li>
                      <li>Real time Chat.</li>
                      <li>Screen Sharing.</li>
                      <li>File Sharing.</li>
                    </ol>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-ui.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
          <div className="mb-40 content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Widget Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      App Widgets are miniature application views that can be
                      embedded in other applications (such as the Home screen)
                      and receive periodic updates. These views are referred to
                      as Widgets in the user interface, and you can publish one
                      with an App Widget provider. widget is a generic term for
                      the part of a GUI that allows the user to interface with
                      the application and operating system. The term widget also
                      can be used to refer to either the graphic component or
                      its controlling program or a combination of both.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />
          </div>
        </section>
        <section className="team section-padding style-6">
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>Technology</span>
                </h2>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/app-technologies.webp"
                  className="mb-30"
                />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    Serving Middle East Countries With Our Android Apps
                  </span>
                </h2>
                <p>
                  Aara Technologies is giving services in all golf countries.
                  Middle-East countries are also coming into the same. As the
                  middle east is not limited to oil, gas, and challenging
                  geopolitics anymore, there is much more. There are around
                  15+Arab states in the middle-east region. These Arab states
                  deserve attention as they have an upcoming tech industry.
                </p>
                <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/Available-Countries.webp" alt="" />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-30">
                  <span>
                    We Have Worked Across Different Verticals And Industries
                  </span>
                </h2>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/food-grocery.svg"
                          className="mb-20"
                        />
                        <Card.Subtitle>Food & Grocery</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/retail.svg"
                          className="w-40 mb-20"
                        />
                        <br />
                        <Card.Subtitle>Retail</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fmcg.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>FMCG</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/real-estate.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Real Estate</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/construction.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Construction</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fashion-apparel.svg" alt="" />
                        <Card.Subtitle>Fashion & Apparel</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/hotel.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Hotel</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/healthcare.svg"
                          className="w-40 mb-20"
                        />
                        <br />
                        <Card.Subtitle>Healthcare</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/telecom.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Telecom</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fintech.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Fintech</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/manufacturing.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Manufacturing</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/automotive.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Automotive</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </section>

        <Faq />
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                Our  <span>Projects</span>
              </h2>
              <p>Here are given our some projects to know about our work.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <ReactnativedevelopmentFAQ />
    </div>
  );
};

export default Reactnative;
