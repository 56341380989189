import React, { useEffect } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import UiuxdesignFAQ from "../../../FAQPages/ServicesFAQ/Uiuxdesign";

const Mlm = (props) => {
  const cards = [
    {
      name: "AEC Website",
      type1: "Web design",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/aec-web.png',
      link: "",
      tags: "UI/UX Design",
    },
    {
      name: "Taiton App",
      type1: "Web design",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/taiton-app.png',
      link: "",
      tags: "UI/UX Design",
    },
    {
      name: "OEMSPA WEB",
      type1: "Web design",
      image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/oemspa-web.png',
      link: "",
      tags: "UI/UX Design",
    },
  ];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/ui-ux-design" />
      </Helmet>
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">UI/UX Design</small>
              <h2 className="mb-20">
                <span> UI/UX Design</span> Company
              </h2>
              <p>We have an experienced team of UI/UX Designer.</p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">Best UI/UX Design Company</small>
                <h2 className="mb-20">
                  <span>UI/UX Design </span>
                </h2>
                <p>
                  Aara Technologies is counted as the Best UI/ UX Design Company
                  in NOIDA, Lucknow. We offer a wide range of customized designs
                  for various kinds of websites. We deal in every type of
                  website design and development such as static and dynamic web
                  design. We have successfully delivered many websites like news
                  portals, school websites, e-commerce websites and etc.
                </p>
                <br />
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/ui-ux.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <h4>
                            Our Key Focus Area in Web site Development Service
                          </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Attractive & Robust Design
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">Responsiveness</h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">Logo Design</h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">Unique Content writing</h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Cyber attacks preliminary checks before live
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Database performance tuning to decrease load
                                time
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Search Engine Optimization before go live.
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Cost effective development.
                              </h6>
                            </li>
                            <li className="d-flex align-items-center ">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Banners and Online advertisement
                              </h6>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h5 className="mb-30">
                        <span>
                          {" "}
                          Aara Technologies has Recognised one of the Best
                          Website Development Company in NOIDA and entire India.{" "}
                        </span>
                      </h5>
                    </div>
                    <p>
                      Aara Technologies is counted as the Best Website Our
                      website design services generate more leads, and sales and
                      help you to grow your business. That is beautiful, work
                      perfectly and focus only for the user experience for Grow
                      your business. We are India’s trusted Web development
                      company catering to variegated demands in different array
                      of web and ui based app development.
                    </p>
                    <p>
                      Web designing is very much important for business owners
                      because it would help them promote their products and
                      services by making people aware of their products and
                      services. Having a website for business, a business owner
                      can expect various benefits like:
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          To get maximum exposure and visibility on the World
                          Wide Web.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          To get more leads for marketing of products and
                          services and to reach out to the target market
                          quickly.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Brand awareness for a new business in very less time.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          A website promotes your business on a 24X7 basis
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <p>
                    But it is very difficult to achieve all these things without a
                    professional website designing company. A website can make a
                    company famous fast if the perfect website designing services
                    are taken care of properly. And as the business grows bigger,
                    it becomes a need for the website to grow and develop and that
                    is where website designing services come in very handy.
                  </p>
                  <p>
                    In the last few years, Aara Technologies has helped many big
                    and small businesses to set up their presence on the web by
                    providing professional and innovative web designing services.
                    We are having our offices in NOIDA, Delhi NCR, and Lucknow but
                    we are not limited only to these physical locations. We offer
                    our services worldwide. Our main areas of operations are
                    India, Singapore, Dubai, the USA, the UK, Rwanda, Cyprus, and
                    more locations.
                  </p>
                  <p>
                    The website design professionals at the company are highly
                    skilled, and experienced and possess the knowledge to create
                    an attractive website with the best features. They always use
                    the latest technologies to create the most effective website.
                    All the latest tools are used in creating the website. These
                    are some of the reasons why we are considered the best website
                    design company in Noida.
                  </p>
                  <p>
                    We as a Website development company situated in Noida also
                    offers several other IT services such as eCommerce solution,
                    Mobile App development, website maintenance, customized
                    software development, and digital marketing services. We tend
                    to provide you with the best customer support services,
                  </p>
                  <p>
                    So, hire a web designing company today to design a website for
                    your business. to increase your online presence and get more
                    business opportunities. Aara Technologies is one of the best
                    options available to design your website a success. We help
                    you get the best possible customization for your website. The
                    team of experts provides you with web designing, hosting, SEO
                    services, domain registration, domain management, and much
                    more.
                  </p>
                  <p>
                    Our eCommerce Web designing services can also help you to
                    create an online store for your products. This is a very
                    effective way to sell your products. With proper web design
                    and interactive features, your customers can order your
                    products through the website without actually going to the
                    store.
                  </p>
                  <p>
                    Parallax designing in websites is mostly used nowadays. This
                    is a very interactive feature of an attractive website because
                    it makes the user feel as if he/she is being transported to
                    different places, with the help of his website. It also
                    enables the user to take decisions.
                  </p>
                  <p>
                    Our best UI/UX designing services also include designing
                    websites based in various languages. These are effective ways
                    of reaching out to a wider audience and getting your website
                    known to a wider audience. This is a very important and
                    effective way if you want to reach out to a larger audience.
                  </p>
                </div>
              </div>
            </div>
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png' alt="" className="lines1" />
            <LazyLoadImage src='https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png' alt="" className="bubble" />

          </div>


          <div className="integration pt-30" data-scroll-index="3">
            <div className="integration pt-60" data-scroll-index="3">
              <div className="text-center section-head style-4">
                <small className="title_small">Steps For Done</small>
                <h2 className="mb-20">
                  Our <span> Working Process</span>{" "}
                </h2>
              </div>
              <div className="container">
                <div className="content">
                  <div className="img">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/provisioning.png"
                      alt=""
                      className="mt-30"
                    />
                    <h3 className="text-center">1. Provisioning</h3>
                  </div>
                  <div className="img">
                    <h3 className="text-center">2. Strategy</h3>
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/strategy.png"
                      alt=""
                      className="mt-30"
                    />
                  </div>
                  <div className="img">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/design%26developement.png"
                      alt=""
                      className="mt-30"
                    />
                    <h3 className="text-center">3. Development</h3>
                  </div>
                  <div className="img">
                    <h3 className="text-center">4. Go Live</h3>
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/go-live.png"
                      alt=""
                      className="mt-30"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">UI/UX Design</small>
              <h2 className="mb-20">
                Our  <span>Projects</span>
              </h2>
              <p>Here are given some our projects below.</p>
            </div>

            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage src={card.image} alt="" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>

                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <UiuxdesignFAQ />
    </div>
  );
};

export default Mlm;
