import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { baseUrl } from '../../../Config/baseUrl'
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const EcommerceappdevelopmentFAQ = () => {
    const [faq, setFaq] = useState([]);
    const [expanded, setExpanded] = useState(null);


    const getFaq = () => {
        axios
            .get(`${baseUrl}/blog/faq-api/`, {
                headers: {
                    /* Authorization: `Token ${localStorage.getItem("token")}`, */
                    Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
                    "Access-Control-Allow-Origin": "*",
                },
                params: {
                    page_id: "12",
                },
            })
            .then((res) => {
                setFaq(res.data?.data);
            })
            .catch((err) => {
                toast.error("Error fetching FAQs", err?.response?.data?.message);
            });
    };


    const handleAccordionChange = (panel) => {
        setExpanded(expanded === panel ? null : panel);
    };

    useEffect(() => {
        getFaq();
    }, []);

    return (
        <div className='flex flex-col items-center justify-center'>
            <p className="pt-10 pb-10 font-serif text-3xl font-bold text-center lg:text-4xl">
                Frequently Asked Questions
            </p>

            {faq?.map((faqItem, index) => (
                <Accordion key={index} className="w-[75%] border-4">
                    <AccordionSummary
                        expandIcon={
                            expanded === index ? <ExpandLess /> : <ExpandMore />
                        }
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                        className="text-xl !font-bold"
                        onClick={() => handleAccordionChange(index)}
                    >
                        <Typography>{faqItem.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {expanded === index && (
                            <Typography>
                                {faqItem.answer.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ').replace(/&amp;/g, ' ')}
                            </Typography>
                        )}
                    </AccordionDetails>
                </Accordion>
            ))}

        </div>
    );
}
export default EcommerceappdevelopmentFAQ