import React from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import EcommercewithcmsFAQ from "../../../FAQPages/ServicesFAQ/Ecommercewithcms";

const Project = (props) => {
  const cards = [
    {
      name: "Dogwalker Website",
      type1: "Web development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/dogwalker.png",
      link: "",
      description:
        "Dogwalker Website app is to sell the pet foods items in different cities.",
      tags: "Ecommerce CMS",
    },
    {
      name: "Amaka Industries",
      type1: "WEBSITE DEVELOPMENT",
      description:
        "Amaka Industry App is sell grocery items through mobile app",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/amakaindustries.png",
      link: "",
      tags: "Ecommerce CMS",
    },
    {
      name: "Thrift shop website",
      type1: "Website DEVELOPEMNT",
      description:
        "Thrift shop is an ecommerece website to shop here many products.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/thriftshop.png",
      link: "",
      tags: "Ecommerce CMS",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <h1 name="h1" content={props.h1} h1 />
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <link rel="canonical" href="https://aaratechnologies.com/e-commerce-app-with-cms" />

      </Helmet>
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">App development</small>
              <h2 className="mb-20">
                <span> eCommerce App </span> with <span>CMS</span>{" "}
              </h2>
              <p>We providing best e-Commerce with CMS Services</p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">
                  eCommerce with CMS Development
                </small>
                <h2 className="mb-20">
                  <span>CMS Mobile App</span>{" "}
                </h2>
                <p>
                  Content Management System generally refers to an web-based
                  application that enables multiple users with different
                  privilege levels to manage any type of data, content or
                  information of any website application, project on the
                  internet forum.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/intro-mobile.webp"
                  className="imgmad mt-30"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="mb-10 img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/woo-mobile.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Ecommerce App with CMS
                      </small>
                      <h2>
                        <span>WooCommerce Mobile </span>App
                      </h2>
                      <br />
                    </div>

                    <p>
                      Aara Technologies has served mobile applications for many
                      famous brands. Some of them are Nokia, HTC, Accenture,
                      Tech Mahindra, etc.We help you to develop a mobile
                      application for your website that facilitates your users
                      the simple navigation. Also, it helps the users to browse
                      your store anytime from anywhere.We offer both Android and
                      iOS applications for your website. Users will experience a
                      great response time for native or cross-platform
                      applications.Includes much advanced application
                      development in terms of innovations, augmented reality
                      features and many more.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-5 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">CMS Web development</small>
                      <h2 className="mb-40">
                        <span>Opencart Mobile App</span>
                      </h2>
                    </div>
                    <p className="mb-20 text">
                      Aara Technologies develops mobile apps that combine great
                      design, top-notch technology, and an understanding of
                      business values.We work in all ways to make sure that
                      every mobile solution we deliver meets your
                      expectations.Working with us you’ll experience the ease of
                      working with smart, experienced, remote teams.We formate
                      your website into an application that facilitates simple
                      navigation i.e. click and go.We’ll optimize your website
                      for both Android and iOS devices.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/opencart-mobile.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/magento-mobile.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Ecommerce with CMS</small>
                      <h2 className="mb-30">
                        <span>Magento Mobile App</span>{" "}
                      </h2>
                    </div>
                    <p>
                      We serve mobile applications for brands such as Nokia,
                      HTC, Accenture, Tech Mahindra, etc. We help you to develop
                      mobile application for your website. The mobile
                      application will facilitate simple navigation to your
                      users through the store.Aara Technologies help you to
                      develop both Android and iOS applications for your
                      website. Experience a good response time for native or
                      cross-platform applications.We Aara Technologies are also
                      in advanced application development in terms of
                      innovations, Augmented Reality features.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">How To Work</small>
                      <h2 className="mb-40">
                        {" "}
                        <span>Add Products</span>
                      </h2>
                    </div>
                    <p className="mb-20 text">
                      Aara Technologies will help you to add your all products
                      on your website with proper description and all necessary
                      things which will be good for your products.E-commerce
                      websites are mainly used for selling products or buying
                      some products so for adding products we will help you.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/add-product.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/notification.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Aara Technologies</small>
                      <h2 className="mb-30">
                        Provide you
                        <span> Notification facility</span>
                      </h2>
                    </div>
                    <p>
                      We serve e-commerce mobile applications where we will
                      provide you Notification facility,By which you always
                      receive notification regarding your products buying or
                      selling.How many vendors are coming on your website that
                      all important things we will send to proper
                      notifications.Aara Technologies will provide you best
                      e-commerce development services with notification
                      facilities.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">How To Work</small>
                      <h2 className="mb-40">
                        <span> Manage Orders</span>
                      </h2>
                    </div>
                    <p>B2C ecommerce services</p>
                    <p className="mb-20 text">
                      Aara tEchnologies design and develop best e-Commerce
                      Mobile application by which you can manage your order
                      too.Vendors will come to your website and you will get a
                      proper notification regarding .Woo commerce will help you
                      create the best e-Commerce Mobile Application.You can
                      manage your multiple orders in a single time.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/app.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=""
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/track_states.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Aara Technologies</small>
                      <h2 className="mb-30">
                        Track Your <span> Stats</span>
                      </h2>
                    </div>
                    <p>
                      In e-Commerce website and Mobile Application we will
                      provide you facility of tracking Reports and sales stats.
                      To manage your online store effectively, you need to know
                      what’s going on. Where your customers come from, how much
                      money they spend, what products they buy, and how your
                      special offers perform. To get these data insights, visit
                      your backend admin or install apps to help you make
                      data-driven decisions and improve your store.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=""
              className="bubble"
            />
          </div>
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">App development</small>
              <h2 className="mb-20">
                Our <span>Projects</span>
              </h2>
              <p>Here are given some related technologies projects.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage
                            src={card.image}
                            alt=""
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <EcommercewithcmsFAQ />
    </div>
  );
};

export default Project;
